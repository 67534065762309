<template>
  <div class="content max-w-screen-2xl">
    <h1>Candles</h1>
    <div class="flex flex-col gap-4">
      <div>
        <div class="flex justify-between gap-4">
          <b-btn
            size="sm"
            variant="white"
            @click="openFilters"
          >
            <div class="flex gap-2 items-center">
              <i class="fas fa-sliders-h"></i>
              <span>Filters</span>
            </div>
          </b-btn>

          <b-btn
            size="sm"
            variant="primary"
            @click="createCandle"
          >
            New candle
          </b-btn>
        </div>
      </div>

      <div>
        <active-filters :activeFilters="activeFilters" />
      </div>

      <div class="font-bold" v-if="candles">
        Showing {{ candles.length | format }} / {{ recordCount | format }} candle(s)
      </div>

      <template v-if="candles">
        <desktop-table
          :candles="candles"
          :filters="filters"
          class="hidden lg:table"
        />
        <mobile-table
          :candles="candles"
          :filters="filters"
          class="lg:hidden"
        />
      </template>
    </div>

    <b-pagination
      v-model="filters.page"
      :total-rows="recordCount"
      :per-page="filters.per_page"
      @change="paginate"
      :disabled="isLoading"
      class="mt-4"
    ></b-pagination>

    <filter-modal
      :data="filterModal"
      :filters="filters"
      :default-filters="defaultFilters"
      @input="scheduleFetching"
    />

    <create-modal
      :data="createModal"
      @created="onCandleCreated"
    />
  </div>
</template>

<script>
import moment from 'moment';
import {
  capitalize,
  decodeFilters,
  encodeFilters,
} from '@/helpers';
import {
  defaultFilters,
} from '@/composables/candle';

const _ = require('lodash');

const FilterModal = () => import('@/components/candles/FilterModal.vue');
const ActiveFilters = () => import('@/components/global/ActiveFilters.vue');
const CreateModal = () => import('@/components/candle/CreateModal.vue');
const DesktopTable = () => import('@/components/candles/DesktopTable.vue');
const MobileTable = () => import('@/components/candles/MobileTable.vue');

export default {
  name: 'Candles',
  components: {
    FilterModal,
    ActiveFilters,
    CreateModal,
    DesktopTable,
    MobileTable,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
    defaultFilters() {
      return defaultFilters;
    },
    activeFilters() {
      const activeFilters = [];
      if (this.filters.date_range !== 'all') {
        const dateRange = this.filters.date_range;
        let dateText = '';
        if (dateRange === 'date') {
          dateText = moment(this.filters.date_from).format('MMM DD, YYYY');
        } else if (dateRange === 'month') {
          dateText = moment(this.filters.date_from).format('MMM YYYY');
        } else if (dateRange === 'year') {
          dateText = moment(this.filters.date_from).format('YYYY');
        }
        activeFilters.push({
          text: `Date: ${dateText}`,
          fn: () => {
            this.filters.date_from = null;
            this.filters.date_to = null;
            this.filters.date_range = 'all';
            this.fetchRecords();
          },
        });
      }
      if (this.filters.candle_status !== null) {
        activeFilters.push({
          text: `Status: ${capitalize(this.filters.candle_status)}`,
          fn: () => {
            this.filters.candle_status = null;
            this.fetchRecords();
          },
        });
      }
      if (this.filters.is_favorite !== null) {
        activeFilters.push({
          text: `Favorite: ${this.filters.is_favorite ? 'Yes' : 'No'}`,
          fn: () => {
            this.filters.is_favorite = null;
            this.fetchRecords();
          },
        });
      }
      if (this.filters.search !== '') {
        activeFilters.push({
          text: `Reference: ${this.filters.search}`,
          fn: () => {
            this.filters.search = '';
            this.fetchRecords();
          },
        });
      }
      if (this.filters.brand_id !== null) {
        const brandText = this.dropdowns.candle_brand.find(
          (option) => option.value === this.filters.brand_id,
        ).text;
        activeFilters.push({
          text: `Brand: ${brandText}`,
          fn: () => {
            this.filters.brand_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.glass_id !== null) {
        const glassText = this.dropdowns.glass.find(
          (option) => option.value === this.filters.glass_id,
        ).text;
        activeFilters.push({
          text: `Glass: ${glassText}`,
          fn: () => {
            this.filters.glass_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.wax_id !== null) {
        const waxText = this.dropdowns.wax.find(
          (option) => option.value === this.filters.wax_id,
        ).text;
        activeFilters.push({
          text: `Wax: ${waxText}`,
          fn: () => {
            this.filters.wax_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.wick_id !== null) {
        const wickText = this.dropdowns.wick.find(
          (option) => option.value === this.filters.wick_id,
        ).text;
        activeFilters.push({
          text: `Wick: ${wickText}`,
          fn: () => {
            this.filters.wick_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.dye_name_id !== null) {
        const dyeText = this.dropdowns.dye.find(
          (option) => option.value === this.filters.dye_name_id,
        ).text;
        activeFilters.push({
          text: `Dye: ${dyeText}`,
          fn: () => {
            this.filters.dye_name_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.fragrance_name_id !== null) {
        const fragranceText = this.dropdowns.fragrance.find(
          (option) => option.value === this.filters.fragrance_name_id,
        ).text;
        activeFilters.push({
          text: `Fragrance: ${fragranceText}`,
          fn: () => {
            this.filters.fragrance_name_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.fragrance_pct_id !== null) {
        const fragrancePctText = this.dropdowns.fragrance_pct.find(
          (option) => option.value === this.filters.fragrance_pct_id,
        ).text;
        activeFilters.push({
          text: `Fragrance %: ${fragrancePctText}`,
          fn: () => {
            this.filters.fragrance_pct_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.additive_name_id !== null) {
        const additiveText = this.dropdowns.additive.find(
          (option) => option.value === this.filters.additive_name_id,
        ).text;
        activeFilters.push({
          text: `Additive: ${additiveText}`,
          fn: () => {
            this.filters.additive_name_id = null;
            this.fetchRecords();
          },
        });
      }

      if (this.filters.additive_pct_id !== null) {
        const additivePctText = this.dropdowns.additive_pct.find(
          (option) => option.value === this.filters.additive_pct_id,
        ).text;
        activeFilters.push({
          text: `Additive %: ${additivePctText}`,
          fn: () => {
            this.filters.additive_pct_id = null;
            this.fetchRecords();
          },
        });
      }

      return activeFilters;
    },
  },
  data() {
    return {
      loadingCount: 0,
      candles: null,
      recordCount: 0,
      scheduleFetching: _.debounce(() => {
        this.fetchRecords();
      }, 500),
      filterModal: {
        showModal: false,
      },
      createModal: {
        showModal: false,
      },
      filters: {
        date_from: null,
        date_to: null,
        date_range: 'all',
        page: 1,
        per_page: 50,
        search: '',
        brand_id: null,
        glass_id: null,
        wax_id: null,
        wick_id: null,
        wick_count_id: null,
        dye_name_id: null,
        fragrance_name_id: null,
        additive_name_id: null,
        dye_pct_id: null,
        fragrance_pct_id: null,
        additive_pct_id: null,
        candle_status: null,
        is_favorite: null,
      },
    };
  },
  methods: {
    createCandle() {
      this.createModal.showModal = true;
    },
    onCandleCreated(candle, viewCandle) {
      this.candles.unshift(candle);
      this.recordCount++;
      if (viewCandle) {
        this.viewCandle(candle);
      }
    },
    viewCandle(candle) {
      this.$router.push({
        path: `/candles/${candle.id}`,
        query: {
          filters: encodeFilters(this.filters, defaultFilters),
        },
      }, () => {});
    },
    paginate() {
      this.$nextTick().then(() => {
        this.fetchRecords(true);
      });
    },
    openFilters() {
      this.filterModal.showModal = true;
    },
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/candle')
        .query(this.filters)
        .then((res) => {
          this.candles = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch candles: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/candle_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch candle count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
      this.setQuery();
    },
    fetchAll() {
      this.fetchRecords();
    },
    setQuery() {
      const encodedFilters = encodeFilters(this.filters, defaultFilters);
      this.$router.replace({
        query: {
          filters: encodedFilters,
        },
      }, () => {});
    },
    getQuery() {
      const urlFilters = this.$route.query.filters;
      if (urlFilters) {
        const decodedFilters = decodeFilters(urlFilters, defaultFilters);
        this.filters = decodedFilters;
      }
    },
  },
  created() {
    this.getQuery();
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
</style>
